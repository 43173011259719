import "core-js/modules/es6.array.find.js";
import { useGetUserQuery, MetricValueKind, useGetSectionQuery } from '~/types/types';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _useGetUserQuery = useGetUserQuery(),
      userResult = _useGetUserQuery.result;
    var user = computed(function () {
      var _userResult$value;
      return ((_userResult$value = userResult.value) === null || _userResult$value === void 0 ? void 0 : _userResult$value.getUser) || {
        locale: 'en-US',
        isAdmin: false
      };
    });
    var variable = computed(function () {
      return {
        id: props.item.id || 0
      };
    });
    var _useGetSectionQuery = useGetSectionQuery(variable),
      result = _useGetSectionQuery.result;
    var items = computed(function () {
      var _result$value;
      return [result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : _result$value.getSection];
    });
    var active = ref([]);
    var selected = computed(function () {
      var _items$value, _items$value$, _items$value$$section;
      if (!active.value.length) return undefined;
      var selectedId = active.value[0];
      return (_items$value = items.value) === null || _items$value === void 0 ? void 0 : (_items$value$ = _items$value[0]) === null || _items$value$ === void 0 ? void 0 : (_items$value$$section = _items$value$.sections) === null || _items$value$$section === void 0 ? void 0 : _items$value$$section.find(function (section) {
        return section.id === selectedId;
      });
    });
    var searchModal = ref(false); // modal used to add children KPIs
    return {
      active: active,
      searchModal: searchModal,
      user: user,
      items: items,
      MetricValueKind: MetricValueKind,
      selected: selected
    };
  }
});